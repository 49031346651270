<template>

<!--  新增学生信息-->
<!-- 校园筛查 筛查计划管理 新增学生页面 -->
  <div class="page">
    <div class="school-form">
      <el-form label-width="168px" :model="ruleForm" :rules="rules" ref="ruleForm" size="mini" class="school-ruleForm el-form">
        <el-form-item v-if="roleType === 9" label="所属学校" prop="school">
          <el-select v-model="ruleForm.school" filterable :filter-method="searchSchool" placeholder="请选择学校" @change="getGradeSelect">
            <el-option
              v-for="item in schoolOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="所属年级" prop="gradeName">
          <el-select v-model="ruleForm.gradeName" filterable clearable allow-create placeholder="请选择年级" @change="getClassSelect">
            <el-option
              v-for="item in gradeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="所属班级" prop="classId">
          <el-select v-model="ruleForm.classId" filterable clearable allow-create placeholder="请选择班级">
            <el-option
              v-for="item in classOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属筛查计划">
          <el-select v-model="ruleForm.longList" multiple placeholder="请选择">
            <el-option
              v-for="item in getNotOutDateScreeningList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="姓名" prop="name">
          <el-input maxlength="10" v-model="ruleForm.name" placeholder="请输入学生姓名"></el-input>
        </el-form-item>

        <el-form-item label="学号" prop="studentId">
          <el-input maxlength="11" v-model="ruleForm.studentId" placeholder="请输入学生学号"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-radio-group v-model="ruleForm.sex">
            <el-radio label="男">男</el-radio>
            <el-radio label="女">女</el-radio>
          </el-radio-group>
        </el-form-item>
          <el-form-item label="出生日期" prop="birthday">
            <el-date-picker
              class="input-class"
              v-model="ruleForm.birthday"
              type="date"
              :picker-options="pickerOptions"
              placeholder="请选择生日"
              value-format="timestamp"
            >
            </el-date-picker>
          </el-form-item>

        <el-form-item label="身份证号" prop="idCard">
        <el-input maxlength="18" v-model="ruleForm.idCard" placeholder="请输入学生的身份证号"></el-input>
        </el-form-item>

        <el-form-item label="手机号码" prop="phone">
          <el-input maxlength="11" v-model="ruleForm.phone" placeholder="请输入家长的手机号">
          </el-input>
        </el-form-item>
        <FormButtonGroup @saveData="submitForm('ruleForm')" save-text="提交" cancel-text="返回"></FormButtonGroup>

      </el-form>
    </div>
  </div>
</template>

<script>
// import StudentsService from '@/globals/service/detection/Students.js'
import studentService from '@/globals/service/management/student.js'
import schoolService from '@/globals/service/detection/school.js'
import selectServicet from '@/globals/service/detection/select.js'
import storage from '@/globals/storage/index.js'
import organizationService from '@/globals/service/management/organization.js'

export default {

  data () {
    return {
      getNotOutDateScreeningList: [],
      pickerOptions: {
        disabledDate: (time) => {
          return time.getTime() > new Date().getTime()
        }
      },
      routerName: '',
      schoolOptions: [],
      gradeOptions: [],
      classOptions: [],
      roleType: null,
      pageSize: 100,
      startPage: 1,
      ruleForm: {
        longList: [],
        school: '',
        gradeName: '',
        classId: '',
        name: '',
        studentId: '',
        sex: '',
        birthday: '',
        idCard: '',
        phone: ''
      },
      rules: {
        school: [
          { required: true, message: '请选择学校', trigger: 'change' }
        ],
        gradeName: [
          { required: true, message: '请选择年级', trigger: 'blur' }
        ],
        classId: [
          { required: true, message: '请选择班级', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请输入学生姓名', trigger: 'change' }
        ],
        studentId: [
          { required: false, message: '请输入学生学号', trigger: 'blur' }
        ],
        sex: [
          { required: true, message: '请输入学生性别', trigger: 'blur' }
        ],
        birthday: [
          { required: true, message: '请选择日期', trigger: 'blur' }
        ],
        idCard: [
          { required: false, pattern: /^\d{15}|\d{}18$/, message: '请输入学生身份证号', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入家长的手机号', trigger: 'blur' },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: '目前只支持中国大陆的手机号码',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  created () {
    this.routerName = this.$route.meta.title ? this.$route.meta.title : ''
  },
  mounted () {
    this.roleType = Number(sessionStorage.getItem('roleType')) || null
    this.getSchoolSelect()
    this.getScreeningList()
  },
  methods: {
    getScreeningList () {
      organizationService.getNotOutDateScreeningList({
        startPage: 1, pageSize: 10000
      }).then(res => {
        const data = []
        res.data.list.forEach(item => {
          const zhi = data.some(items => items.value === item.id)
          if (!zhi) {
            data.push({
              value: item.id,
              label: item.screeningName
            })
          }
        })
        this.getNotOutDateScreeningList = data
      })
    },
    goTo (path, query) {
      this.$router.push({ path: path, query: query })
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const that = this
          that.fullscreenLoading = true
          const ruleForm = this.ruleForm
          studentService.addStudent(ruleForm).then(res => {
            this.$message({
              message: res.errMsg,
              type: 'success',
              duration: 1000,
              onClose: function () {
                that.$router.go(-1)
                that.fullscreenLoading = false
              }
            })
          }).catch(() => {
            that.fullscreenLoading = false
          })
        } else {
          this.$message.error('请填入相应信息！')
          return false
        }
      })
    },
    searchSchool (row) {
      schoolService.search({ name: row }).then(res => {
        const schoolList = res.data || []
        const schoolOptions = []
        schoolList.forEach(item => {
          schoolOptions.push({
            value: item.id,
            label: item.schoolName
          })
        })
        this.schoolOptions = schoolOptions
      })
    },
    getSchoolSelect () {
      const schoolId = storage.get('schoolId')
      if (schoolId) {
        this.ruleForm.school = schoolId
        this.getGradeSelect()
        return
      }
      this.ruleForm.school = ''
      this.ruleForm.gradeName = null
      this.ruleForm.classId = null
      this.schoolOptions = []
      this.gradeOptions = []
      this.classOptions = []
      selectServicet.school({ startPage: this.startPage, pageSize: this.pageSize }).then(res => {
        const schoolList = res.data.schoolList
        const schoolOptions = []
        schoolList.forEach(item => {
          schoolOptions.push({
            value: item.id,
            label: item.schoolName
          })
        })
        if (this.roleType === '10') {
          this.ruleForm.school = schoolList[0].id || null
          this.getGradeSelect()
        }
        this.schoolOptions = schoolOptions
      })
    },
    getGradeSelect () {
      this.searchSchool('')
      if (!this.ruleForm.school) {
        return false
      }
      this.ruleForm.gradeName = null
      this.ruleForm.classId = null
      this.gradeOptions = []
      this.classOptions = []
      selectServicet.grade({ schoolId: this.ruleForm.school + '' }).then(res => {
        const gradeList = res.data
        const gradeOptions = []
        gradeList.forEach(item => {
          gradeOptions.push({
            value: item.id,
            label: item.gradeName
          })
        })
        this.gradeOptions = gradeOptions
        this.getClassSelect()
      })
    },
    getClassSelect () {
      const gradeName = this.ruleForm.gradeName
      let gradeId = ''
      if (!gradeName) {
        return false
      }
      this.gradeOptions.forEach(item => {
        if (item.label === gradeName) {
          gradeId = item.value
        }
      })
      if (!gradeId) {
        return false
      }
      this.ruleForm.classId = null
      this.classOptions = []
      selectServicet.class({ gradeId: gradeId }).then(res => {
        const classList = res.data
        const classOptions = []
        classList.forEach(item => {
          classOptions.push({
            value: item.id,
            label: item.className
          })
        })
        this.classOptions = classOptions
      })
    },
    returnPage () {
      if (window.history.length <= 1) {
        this.$router.push({ path: '/login' })
        return false
      } else {
        this.$router.go(-1)
      }
    }
  }

}
</script>
<style lang="scss" scoped>
.page{
  padding: 10px 20px;
  background-color: #fff;
  min-height: 100%;
}
.header-container{
  width: 100%;
  height: 43px;
  border-bottom: 1px solid #f5f5f5;
  .header-section{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .route-name{
      line-height: 43px;
      font-size: 12px;
      color: #51B25F;
    }
    .button-section{
      display: flex;
      align-items: center;
    }
  }
}
.school-form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 24px 0;
  box-sizing: border-box;
  max-width: 600px;

  .input-class{
    width: 175px;
  }
}
.red{
    color: #f50e0e;
  }
</style>
